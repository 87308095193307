import React from 'react'
import { Heading } from '@chakra-ui/react'
import Header from '../components/header'

export default function Home() {
        return (
                <>
                        <Header />
                        <Heading as="h1" fontSize="3xl" m="4" align="center">
                                Nicci Topping Self Tape Submissions
                        </Heading>
                </>
        )
}
